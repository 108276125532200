export function toUnit(calculator) {
  return function toUnitFn() {
    var _options$digits;

    for (var _len = arguments.length, _ref = new Array(_len), _key = 0; _key < _len; _key++) {
      _ref[_key] = arguments[_key];
    }

    var dineroObject = _ref[0],
        options = _ref[1];
    var round = (options === null || options === void 0 ? void 0 : options.round) || identity;

    var _dineroObject$toJSON = dineroObject.toJSON(),
        amount = _dineroObject$toJSON.amount,
        currency = _dineroObject$toJSON.currency,
        scale = _dineroObject$toJSON.scale;

    var power = calculator.power,
        toNumber = calculator.toNumber;
    var toUnitFactor = toNumber(power(currency.base, scale));
    var factor = toNumber(power(currency.base, (_options$digits = options === null || options === void 0 ? void 0 : options.digits) !== null && _options$digits !== void 0 ? _options$digits : scale));
    return round(toNumber(amount) / toUnitFactor * factor) / factor;
  };
}

function identity(value) {
  return value;
}