import { toUnit } from './toUnit';
export function toFormat(calculator) {
  var toUnitFn = toUnit(calculator);
  return function toFormatFn() {
    for (var _len = arguments.length, _ref = new Array(_len), _key = 0; _key < _len; _key++) {
      _ref[_key] = arguments[_key];
    }

    var dineroObject = _ref[0],
        transformer = _ref[1];

    var _dineroObject$toJSON = dineroObject.toJSON(),
        currency = _dineroObject$toJSON.currency,
        scale = _dineroObject$toJSON.scale;

    var amount = toUnitFn(dineroObject, {
      digits: scale
    });
    return transformer({
      amount: amount,
      currency: currency,
      dineroObject: dineroObject
    });
  };
}