import { toFormat as coreToFormat } from '@dinero.js/core';

/**
 * Format a Dinero object with a custom transformer.
 *
 * @param dineroObject - The Dinero object to format.
 * @param transformer - A transformer function.
 *
 * @returns The object as a formatted string.
 */
export function toFormat() {
  for (var _len = arguments.length, _ref = new Array(_len), _key = 0; _key < _len; _key++) {
    _ref[_key] = arguments[_key];
  }

  var dineroObject = _ref[0],
      transformer = _ref[1];
  var calculator = dineroObject.calculator;
  var formatter = coreToFormat(calculator);
  return formatter(dineroObject, transformer);
}